import { DayGoalGraph } from '@component/charts/day-goal-graph.component';
import { getDiariesWithEmptyValues } from '@component/charts/utils';
import { GatsbyButton } from '@components/atm.button/button.component';
import { ChatfuelBroadcastRequest } from '@global/utils/chat/chatfuel';
import { roundGradeValue } from '@global/utils/domain/entities';
import { DailyGoals, getTitleForDayGoal } from '@global/utils/domain/goals';
import { Query_Root, Sleep_Diary } from '@global/utils/remote-graphql-types';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import WebviewLayout from '@lp-root/src/components/org.layout/webview-layout.component';
import { SleepDiaryQuery } from '@lp-root/src/data/graphql/hasura/sleep-diary.query.hasura';
import { buildChatfuelBroadcastRequest } from '@lp-root/src/data/request-utils/redirect.request';
import { PageProps } from '@lp-src/utils/local-types';
import { BodySecondary, Col, FaIcon, Grid, H1, H2, Row, Separator } from '@web/atomic';
import { OpinionatedColorStyled } from '@web/atomic/atm.opinionated-color/opinionated-color.styled';
import { StickButtonWrapper } from '@web/atomic/atm.wrapper/wrapper.component';
import { Placeholder } from '@web/atomic/legacy/mol.placeholder';
import { ShimmerInput } from '@web/atomic/legacy/mol.shimmer/shimmer.options';
import { Table, TD, TH, THead, TR } from '@web/atomic/legacy/mol.table';
import { LoadingState } from '@web/atomic/legacy/obj.loading-state';
import { useClientRect } from '@web/atomic/obj.custom-hooks/client-rect.hook';
import { useCloseMessengerModalCallback } from '@web/atomic/obj.custom-hooks/close-messenger-modal.hook';
import { usePostUsingMessenger } from '@web/data/use-post-using-messenger.hook';
import { useQueryCustom } from '@web/data/use-query-custom.hook';
import { UserIdDataSource } from '@web/data/user-id.datasource';
import { PostUrl } from '@web/data/vigilantes.datasource';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import React, { useMemo } from 'react';

const GraficoObjetivoDiaPage: React.FunctionComponent<PageProps> = (props) => {
  const { data, error, loading } = useQueryCustom(SleepDiaryQuery);
  const summary = useDayGoalSummary(data);

  const [rect, titleRef] = useClientRect();

  const { close, loading: waitCloseLoading } = useCloseMessengerModalCallback();
  const [post, { loading: closeLoading }] = usePostUsingMessenger<ChatfuelBroadcastRequest>({
    onSuccess: close,
    url: PostUrl.RedirectUser,
  });
  const handleClose = async () => {
    console.log('DIARY_DEBUG: grafico-objetivo-dia.tsx ~ line 39 ~ handleClose ~ userId');
    const userId = await UserIdDataSource.getId();
    const request = buildChatfuelBroadcastRequest(userId, 'goal_review_day_b2', {
      'global-day_start_avg': summary.first,
      'global-day_week_avg': summary.current,
      'global-day_global_avg': summary.everything,
      'global-day_improve_week': Math.round(((summary.current - summary.first) / summary.first) * 100),
      'global-day_improve_global': Math.round(((summary.everything - summary.first) / summary.first) * 100),
      'global-day_improve': summary.current > summary.first || summary.everything > summary.first,
    });
    return post(request);
  };

  const diaries = getDiariesWithEmptyValues(data && data.sleep_diary);

  return (
    <WebviewLayout>
      <SEO
        socialMedia={{
          canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
          title: 'Objetivo do dia',
          description: 'Veja como você está evoluindo no seu objetivo do dia',
        }}
      />

      <Grid>
        <Row mt mb>
          <Col xs={12}>
            <H1 ref={titleRef}>Objetivo do dia</H1>
          </Col>
        </Row>
        <LoadingState loading={loading} error={!!error} data={!!diaries}>
          {/* TODO: UI - ajustar loading */}
          <LoadingState.Shimmer>
            <>
              {Array(3)
                .fill(1)
                .map((_item, index) => (
                  <Row mt mb key={index}>
                    <Col xs={12} sm={12} md={7} lg={7}>
                      <ShimmerInput />
                      <Separator />
                    </Col>
                  </Row>
                ))}
            </>
          </LoadingState.Shimmer>
          <LoadingState.Error>
            <Placeholder
              icon={<FaIcon.FlashAlert size="9x" />}
              title={'Erro ao pegar seus dados'}
              description="Tente novamente mais tarde"
            />
          </LoadingState.Error>
          <LoadingState.NoData>
            <Placeholder
              icon={<FaIcon.Table size="9x" />}
              title={'Seu diário está em branco'}
              description={`Quando você preencher seu diário, os seus dados podem ser vistos nessa tela, ok? Preencha 1 vez e veja como fica aqui!`}
            >
              <GatsbyButton onClick={close}> Combinado! </GatsbyButton>
            </Placeholder>
          </LoadingState.NoData>

          {summary && (
            <Row mt mb>
              <Col xs={12}>
                <H2> Melhorar {summary.title}</H2>
                <BodySecondary
                  role="tooltip"
                  data-microtip-position="top"
                  data-microtip-size="large"
                  aria-label={'Para o cálculo, considerou-se que: 🙂 = 100 (bom); 😐 = 50 (médio); 😞 = 0 (ruim)'}
                >
                  Veja abaixo os valores médios do seu objetivo do dia (de 0 a 100) <FaIcon.Question />:
                </BodySecondary>
                <Separator />
                <Table>
                  <THead>
                    <TR>
                      <TH textAlign="left">No início</TH>
                      <TH textAlign="left">Atualmente</TH>
                      <TH textAlign="left">Em todo programa</TH>
                    </TR>
                  </THead>
                  <DayGoalTableRow {...(summary as SleepDataSummary)} />
                </Table>
              </Col>
            </Row>
          )}

          {data && (
            <Row mt mb>
              <Col xs={12}>
                <H2>Todos dados</H2>
                <DayGoalGraph width={rect && rect.width} sleepDiaryData={diaries} user={data.user[0]} />
              </Col>
            </Row>
          )}
          {!loading && (
            <StickButtonWrapper>
              <GatsbyButton onClick={handleClose} expanded loading={closeLoading || waitCloseLoading}>
                Continuar
              </GatsbyButton>
            </StickButtonWrapper>
          )}
        </LoadingState>
      </Grid>
    </WebviewLayout>
  );
};

export default GraficoObjetivoDiaPage;

export const query = graphql`
  query GraficoObjetivoDiaPage {
    site {
      ...SiteUrl
    }
  }
`;

////////////////////////////////////////////////////////////////////////////////////////////////////

type ISleepRestrictionCycleTableRowProps = SleepDataSummary;

const DayGoalTableRow: React.FunctionComponent<ISleepRestrictionCycleTableRowProps> = (props) => {
  return (
    <TR>
      <TD textAlign="left">{props.first}</TD>
      <TD textAlign="left">
        <OpinionatedColorStyled good={props.current > props.first}>{props.current}</OpinionatedColorStyled>
      </TD>
      <TD textAlign="left">
        <OpinionatedColorStyled good={props.everything > props.first}>{props.everything}</OpinionatedColorStyled>
      </TD>
    </TR>
  );
};

////////////////////////////////////////////////////////////////////////////////////////////////////

interface SleepDataSummary {
  title: string;
  first: number;
  current: number;
  everything: number;
}

/**
 * Get user day goal averages
 * @param data
 */
const useDayGoalSummary = (data: Query_Root) => {
  return useMemo(() => {
    if (!data || !data.sleep_diary) {
      return {} as SleepDataSummary;
    }

    const userDayGoal: DailyGoals = data.user[0].day_goal;
    const filteredData = data.sleep_diary.filter((item) => {
      return getValueForDayGoal(userDayGoal, item);
    });

    const everythingCount = filteredData.length;
    const weekDays = 7;

    const summary: Partial<SleepDataSummary> = filteredData.reduce(
      (acc, curr, index) => {
        const value = getValueForDayGoal(userDayGoal, curr);
        acc.everything += value;
        const isFirstWeek = index < weekDays;
        if (isFirstWeek) acc.first += value;
        const isCurrentWeek = index > everythingCount - 1 - weekDays;
        if (isCurrentWeek) acc.current += value;
        return acc;
      },
      { first: 0, current: 0, everything: 0 }
    );

    summary.first /= weekDays;
    summary.current /= weekDays;
    summary.everything /= everythingCount;

    summary.first = roundGradeValue(summary.first);
    summary.current = roundGradeValue(summary.current);
    summary.everything = roundGradeValue(summary.everything);

    summary.title = getTitleForDayGoal(userDayGoal, true);

    return summary;
  }, [data]);
};

function getValueForDayGoal(userDayGoal: DailyGoals, item: Sleep_Diary) {
  switch (userDayGoal) {
    case DailyGoals.Humor:
      return item.humor;
    case DailyGoals.Concentration:
      return item.concentration;
    case DailyGoals.Energy:
      return item.energy;
    case DailyGoals.Relationships:
      return item.relationships;
    default:
      console.error('ERROR: useSummary -> invalid userDayGoal', userDayGoal);
      return null;
  }
}
